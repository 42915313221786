import { KvProvider } from "./Modules/index/KvProvider";
(function () {
    "use strict";

    const kv = new KvProvider();
    kv.init();
    let time =
      kv.timeFirstCross +
      kv.timeNomalCross * (kv.totalNum - 1) +
      kv.timeTransition * kv.totalNum +
      kv.timeEachDelay * (kv.totalNum - 1);
})();

