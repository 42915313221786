import { KvImage } from './KvImage';
import { UserHelpers } from '../../helper.main';
export class KvProvider {
    public dom:any;
    public container: KvImage[] = [];
    private running:boolean = false;
    public timeTransition = 2400;
    public timeFirstCross = 0;
    public timeNomalCross = 5600;
    public timeEachDelay = 0;
    public totalNum = 2;
    constructor(){
        this.dom = {
            holder: document.querySelector('.pKv__holder') as HTMLElement,
        };
        // console.log('KvProvider constructor',this);
    }
    init(){
        // this.timeFirstCross = this.timeNomalCross - this.timeTransition;
        this.timeFirstCross = 0;
        const images = document.querySelectorAll('.pKv__pic--each');
        for (let i = 0, len = images.length; i < len; i++) {
            let col = new KvImage(this, images[i]);
            this.container.push(col);
        }
        this.addEvent();
        this.createDom();
    }
    createDom(){
        const _holder = this.dom.holder.querySelector('.pKv__pic--wrapper');
        for (let i = 0; i < this.container.length; i++) {
            _holder.appendChild(this.container[i].imageWrapper.cloneNode(true))
        }
        for (let i = 0; i < this.container.length; i++) {
            _holder.appendChild(this.container[i].imageWrapper.cloneNode(true))
        }
        this.move();
    }
    addEvent(){
        const me = this
        this.dom.holder.querySelector('.pKv__pic--wrapper').addEventListener(UserHelpers.transitionEnd(),e=>{
            if ('transform' !== e.propertyName) { return }
            
            this.dom.holder.querySelector('.pKv__pic--wrapper').style.transition = '';
            this.dom.holder.querySelector('.pKv__pic--wrapper').style.transform = '';
            setTimeout(function(){
                me.move();
            },10)
            
        })
    }
    move(){
        let sec = (window.matchMedia( "(max-width: 767px) and (orientation: portrait)" ).matches) ? 16 : 60;
        this.dom.holder.querySelector('.pKv__pic--wrapper').style.transition = 'transform '+sec+'s linear';
        this.dom.holder.querySelector('.pKv__pic--wrapper').style.transform = 'translate3d(-33.33333333%,0,0)';
    }
}




