import { KvProvider } from "./KvProvider";
// import { KvImagesEachCol } from './KvImagesEachCol';
import {UserHelpers} from '../../helper.main';
export class KvImage {
    public dom: any;
    private provider: KvProvider;
    // private parentHolder: KvImagesEachCol;
    public imageWrapper: Element;
    public next: KvImage;
    // public next: KvImage | boolean;
    constructor(provider: KvProvider, imgWrapper: Element) {
        this.provider = provider;
        // this.parentHolder = parentHolder;
        this.imageWrapper = imgWrapper;
        this.addEvent();
        // console.log('KvImage', this);
    }
    show(){
        this.imageWrapper.classList.remove('-hid');
        this.imageWrapper.classList.add('-showing');
    }
    hide() {
        this.imageWrapper.classList.remove('-showed');
        this.imageWrapper.classList.add('-hiding')
    }
    addEvent(){
        let me = this;
        this.imageWrapper.addEventListener(UserHelpers.transitionEnd(),function(e){
            if('opacity' !== e.propertyName){return}
            if (me.imageWrapper.classList.contains('-showing')){
                me.imageWrapper.classList.add('-showed');
                me.imageWrapper.classList.remove('-showing');
            }
            if (me.imageWrapper.classList.contains('-hiding')) {
                me.imageWrapper.classList.add('-hid');
                me.imageWrapper.classList.remove('-hiding');
            }
        });
    }
}